import { useInfiniteQuery, useQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';

// ********** Get RL **************
export const getRLId = async (job: string) => {
  const { data: rl_id } = await apiService.get(
    `/api/light/jobs/${job}/roof_layout_id/`
  );
  return rl_id;
};

const getLayoutInfo = async (id: number) => {
  const { data } = await apiService.get(`/api/rooflayouts/${id}/`);

  return data;
};

export const useLayoutInfo = (id?: number) => {
  // @ts-ignore
  return useQuery(['job-roof-layout', id], () => getLayoutInfo(id), {
    staleTime: 0,
    enabled: !!id,
  });
};

// ********** Get Rooflayout building field config **********
const getRLBuildingFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    `/api/light/rooflayouts/buildings/fields_config/`,
    q
  );
  return data;
};

interface IFieldConfig {
  building?: number;
}

export const useRLBuildingFieldConfig = (q: IFieldConfig = {}) => {
  const { building } = q;
  return useQuery(
    ['rlb-field-config', { building }],
    () => getRLBuildingFieldConfig({ building }),
    {
      staleTime: building ? 0 : Infinity,
    }
  );
};

export const createBuilding = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(
    '/api/rooflayouts/buildings/',
    payload
  );
  return data;
};

export const updateBuilding = async (
  id: number,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/rooflayouts/buildings/${id}/`,
    payload
  );
  return data;
};

export const deleteBuilding = async (id: number) => {
  await apiService.delete(`/api/rooflayouts/buildings/${id}/`);
  return null;
};

export const updateBuildingCenter = async (
  id: number,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/rooflayouts/buildings/${id}/`,
    payload
  );
  return data;
};

export const createLine = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('/api/rooflayouts/lines/', payload);
  return data;
};

export const deleteLine = async (id: number) => {
  const { data } = await apiService.delete(`/api/rooflayouts/lines/${id}/`);
  return data;
};

export const updateLineType = async (
  id: string,
  payload: Record<string, any>
) => {
  const { data } = await apiService.patch(
    `/api/rooflayouts/lines/${id}/`,
    payload
  );
  return data;
};

export const createLineAccessory = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(
    '/api/rooflayouts/line-accessories/',
    payload
  );
  return data;
};

export const deleteLineAccessory = async (id: number) => {
  const { data } = await apiService.delete(
    `/api/rooflayouts/line-accessories/${id}/`
  );
  return data;
};

export const createPoint = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('/api/rooflayouts/points/', payload);
  return data;
};

export const deletePoint = async (id: number) => {
  await apiService.delete(`/api/rooflayouts/points/${id}/`);
};

export const createPitch = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('/api/rooflayouts/pitches/', payload);
  return data;
};

export const deletePitch = async (id: number) => {
  await apiService.delete(`/api/rooflayouts/pitches/${id}/`);
};

export const createAreaAccessory = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(
    '/api/rooflayouts/area-accessories/',
    payload
  );
  return data;
};

export const deleteAreaAccessory = async (id: number) => {
  await apiService.delete(`/api/rooflayouts/area-accessories/${id}/`);
};

export const createFlatRoof = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(
    '/api/rooflayouts/flatareas/',
    payload
  );
  return data;
};

export const deleteFlatRoof = async (id: number) => {
  await apiService.delete(`/api/rooflayouts/flatareas/${id}/`);
};

export const createTwoStories = async (payload: Record<string, any>) => {
  const { data } = await apiService.post(
    '/api/rooflayouts/twostories/',
    payload
  );
  return data;
};

export const deleteTwoStories = async (id: number) => {
  await apiService.delete(`/api/rooflayouts/twostories/${id}/`);
};

// Manual rooflayouts
export const getManualRooflayouts = async ({ pageParam = 1, ...q }) => {
  const { data } = await apiService.get('/api/light/manual-rooflayouts/', {
    ...q,
    page: pageParam,
  });
  return data;
};

export const useManualRooflayouts = (q: Record<string, any>) => {
  return useInfiniteQuery(
    ['manual-rooflayouts', q],
    ({ pageParam }) => getManualRooflayouts({ pageParam, ...q }),
    {
      getNextPageParam: (lastPage) => {
        return lastPage.next_page ?? undefined;
      },
      staleTime: 15 * 60 * 1000,
    }
  );
};

export const useDesktopManualRooflayouts = (
  q: Record<string, any>,
  page = 1
) => {
  return useQuery(
    ['desktop-manual-rooflayouts', q, { page }],
    () => getManualRooflayouts({ pageParam: page, ...q }),
    {
      staleTime: 5 * 60 * 1000,
      keepPreviousData: true,
    }
  );
};

const getNearmapSurveys = async (q: Record<string, any> = {}) => {
  const { data } = await apiService.get(
    '/api/nearmap/integration/get_surveys/',
    q
  );
  return data;
};

export const useNearmapSurveys = (q: Record<string, any>) => {
  return useQuery(['nearmap-survey', q], () => getNearmapSurveys(q), {
    staleTime: Infinity,
  });
};

interface IFieldConfig {
  job?: string;
  mrlId?: string;
}

const getManualRLFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get(
    '/api/light/manual-rooflayouts/fields_config/',
    q
  );
  return data;
};

export const useManualRLFieldConfig = (q: IFieldConfig = {}) => {
  const { job, mrlId } = q;
  return useQuery(
    ['manual-roof-layout', { job, mrlId }],
    () => getManualRLFieldConfig(q),
    {
      staleTime: mrlId ? 0 : Infinity,
    }
  );
};

export const createRLMeasurements = async (
  payload: Record<string, any>,
  q?: Record<string, any>
) => {
  const { data } = await apiService.post(
    '/api/light/manual-rooflayouts/',
    payload,
    q
  );
  return data;
};

export const updateRLMeasurements = async (
  id: string,
  job: string,
  payload: Record<string, any>,
  q: Record<string, any>
) => {
  const { data } = await apiService.put(
    `/api/light/manual-rooflayouts/${id}/?job=${job}`,
    payload,
    q
  );
  return data;
};

export const deleteMeasurement = async (id: number, job: string) => {
  await apiService.delete(`/api/light/manual-rooflayouts/${id}/?job=${job}`);
};

export const markAsDefault = async (id: number, job: string) => {
  await apiService.post(
    `/api/light/manual-rooflayouts/${id}/make_as_default/?job=${job}`
  );
};

export const saveToDocs = async (id: number) => {
  const { data } = await apiService.post(
    `/api/light/rooflayouts/buildings/${id}/save_to_docs/`
  );
  return data;
};

export const setNearmapSurvey = async (
  layoutId: number,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/nearmap/integration/surveys/?layoutId=${layoutId}`,
    payload
  );
  return data;
};

const getPurchasedSurveys = async (jobId: string) => {
  const { data } = await apiService.get('/api/nearmap/surveys/', {
    job: jobId,
  });
  return data?.results || [];
};

export const usePurchasedSurveys = (job: string, enabled = true) => {
  return useQuery(['nearmap-surveys', job], () => getPurchasedSurveys(job), {
    staleTime: 5 * 60 * 1000,
    enabled,
  });
};

export const uploadEagleviewXml = async (
  id: number,
  payload: Record<string, any>
) => {
  const { data } = await apiService.post(
    `/api/rooflayouts/${id}/eagle_view/`,
    payload
  );
  return data;
};
