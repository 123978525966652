import React, { useCallback } from 'react';
import clx from 'classnames';
import Icon from 'components/Icon';
import toast from 'react-hot-toast';
import useModal from 'hooks/useModal';
import BottomSheet from 'components/BottomSheet';
import { Link, useNavigate } from 'react-router-dom';
import UpdateEmail from './UpdateEmail';
import useMedia from 'hooks/useMedia';
import CreateEmail from 'pages/JobDetail/components/CreateEmail/ModalForm';

type AppProps = {
  job: Record<string, any>;
  iconSize?: number;
  className?: string;
};

const Email: React.FC<AppProps> = ({ job, iconSize = 32, className = '' }) => {
  const { isMobile } = useMedia();
  const navigate = useNavigate();
  const [create, showCreate, closeCreate] = useModal();
  const [updateEmailModal, showUpdateEmailModal, closeUpdateEmailModal] =
    useModal();

  const iconStyle = (value: string | null) =>
    clx(className, {
      'text-text-lighter': !value,
      'text-primary': !!value,
    });

  const noClickStyle = (value: string | null) =>
    clx({
      'pointer-events-none': !value,
    });

  const onClick = useCallback(() => {
    if (!job.customer?.email) {
      toast.error('No email found!', { position: 'top-center' });
      return showUpdateEmailModal();
    }
    if (isMobile) {
      return navigate(
        `/jobs/${job.id}/emails/create?initial_recipient=${job.customer?.user_id}`
      );
    }
    return showCreate();
  }, [job, isMobile]);

  return (
    <>
      <div
        className={`${noClickStyle(
          job.customer?.email
        )} relative bottom-[2px] cursor-pointer`}
        onClick={onClick}
      >
        <Icon
          name="email"
          size={iconSize}
          className={iconStyle(job.customer?.email)}
        />
      </div>
      {create && (
        <CreateEmail
          open={create}
          closeModal={closeCreate}
          initial_recipient={job.customer.user_id}
        />
      )}
      {updateEmailModal && (
        <BottomSheet open={updateEmailModal}>
          <UpdateEmail
            closeModal={closeUpdateEmailModal}
            // onSuccess={() => {
            //   return navigate(
            //     `/jobs/${job.id}/emails/create?initial_recipient=${job.customer.user_id}`
            //   );
            // }}
          />
        </BottomSheet>
      )}
    </>
  );
};

export default Email;
