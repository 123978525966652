import React from 'react';
import clx from 'classnames';
import useMedia from 'hooks/useMedia';

type AppProps = {
  tooltipContent: React.ReactNode;
  children: React.ReactNode;
  width?: string;
  background?: string;
  position?: string;
};

const Tooltip: React.FC<AppProps> = ({
  tooltipContent,
  children,
  background = 'bg-gray-800',
  position = 'top-4 left-2 mt-2',
}) => {
  const { isMobile } = useMedia();
  return (
    <div className="group relative">
      {children}
      {!isMobile && !!tooltipContent && (
        <span
          className={clx(
            `custom-tooltip whitespace-pre-line absolute scale-0 transition-all min-w-max max-w-sm rounded p-2 text-xs text-white border bg-white group-hover:scale-100 group-hover:z-9999`,
            background,
            position
          )}
          // style={{ width: width ?? undefined }}
        >
          {tooltipContent}
        </span>
      )}
    </div>
  );
};

export default Tooltip;
