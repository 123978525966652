import { capitalize, get, isObject, toLower, truncate } from 'lodash';
import { FileType } from './enum';
import heic2any from 'heic2any';
import { v4 } from 'uuid';
import { getItem } from './localstorage';
import { IMPERSONATE_SESSION_KEY, SESSION_KEY } from './constants';
import axios from 'axios';
import toast from 'react-hot-toast';

const pat = /^https?:\/\//i;

export const urlRegex =
  /^(ftp|http|https):\/\/([a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+)(\/[\w-]*)*(\/[\w-]*)*(\?[a-zA-Z0-9_]+=[\w-]+(&[a-zA-Z0-9_]+=[\w-]+)*)?$/gm;

export const isAbsoluteUrl = (url: string): boolean => {
  return pat.test(url);
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const formatAmt = (amt: number) => {
  return formatter.format(amt ?? 0);
};

export const formatAmountOrDefault = (amt: number) => {
  if (amt === null || amt === undefined) {
    return 'N/A';
  }
  return formatter.format(amt);
};

export const formatNumber = (num: number) => {
  return new Intl.NumberFormat('en-US').format(num ?? 0);
};

export const shortenLabel = (label: string) => {
  const label_arr = label.split(' ');
  return label_arr
    .map((l) => {
      if (l.length > 12) {
        return `${l.slice(0, 10)}..`;
      }
      return l;
    })
    .join(' ');
};

export const openGoogleMapUrl = (lat: number, lng: number) => {
  window.open(
    `https://www.google.com/maps/dir/?api=1&destination=${lat},${lng}`,
    '_blank'
  );
};

export const getFNName = (name: string) => {
  const nameArr = name.split(' ');
  return {
    first_name: nameArr[0],
    last_name: nameArr.slice(1, nameArr.length).join(' ') || '-',
  };
};

export const getFileExtn = (name: string) => {
  const _nameArr = name.split('.');
  const extn = (_nameArr[_nameArr.length - 1] || 'unknown').toUpperCase();
  return extn.toLowerCase();
};

export const getFileType = (name: string) => {
  const _nameArr = name?.split('.');
  const extn = (_nameArr[_nameArr.length - 1] || 'unknown').toUpperCase();
  if (['PDF'].includes(extn)) {
    return FileType.Pdf;
  }
  if (['DOC', 'DOCX'].includes(extn)) {
    return FileType.Doc;
  }
  if (['XLS', 'XLSX', 'CSV'].includes(extn)) {
    return FileType.Excel;
  }
  if (['PNG', 'JPG', 'JPEG', 'GIF'].includes(extn)) {
    return FileType.Img;
  }
  return null;
};

export const getFileIcon = (name: string) => {
  const fileType = getFileType(name || '');
  const iconMap = {
    [FileType.Pdf]: 'pdf',
    [FileType.Doc]: 'document',
    [FileType.Excel]: 'excel',
    [FileType.Img]: 'image',
  };
  return fileType ? iconMap[fileType] || 'document' : 'document';
};

export const getQueryParams = (iter: any) => {
  const ret: Record<string, any> = {};
  for (const item of iter) {
    const [param, value] = item;
    if (param !== 'label') {
      ret[param] = value;
    }
  }
  return ret;
};

export const getBarMultiplier = (totalItems: number) => {
  if (totalItems <= 20) {
    return 30;
  }
  return 25;
};

export const getNumberSelectLabel = (
  selectedCount: number,
  totalCount: number
) => {
  if (selectedCount === totalCount) {
    return 'All';
  }
  return `Top ${selectedCount}`;
};

export const kFormatter = (num: number) => {
  if (num > 999999) {
    // @ts-ignore
    return `$${Math.sign(num) * (Math.abs(num) / 1000000).toFixed(1)}M`;
  }
  if (num > 999) {
    // @ts-ignore
    return `$${Math.sign(num) * (Math.abs(num) / 1000).toFixed(1)}k`;
  }
  return num.toString();
};

export const formatPhoneNumber = (phoneNumberString: string) => {
  const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '+1 ' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return null;
};

export const readAxiosErr = (err: any) => {
  if (err.response) {
    const data = err.response.data;
    if (isObject(data)) {
      return Object.entries(data)
        .map(([k, v]) => v)
        .join(', ');
    }
  }
  return 'Unable to perform this action!';
};

export const groupBy = (array: Record<string, any>[], groupBy: string) => {
  return array.reduce((acc: any, curr: any) => {
    const key = curr[groupBy];
    acc[key] = acc[key] ?? [];
    acc[key].push(curr);
    return acc;
  }, {});
};

export const actionLabel = (
  objId: string | number | boolean | null | undefined,
  suffix: string,
  loading = true
) => {
  if (objId) {
    return loading ? 'Updating...' : `Update ${suffix}`;
  }
  return loading ? 'Creating...' : `Create ${suffix}`;
};

export const emptyStringToNull = (
  value: string | number,
  originalValue: string | number
) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return undefined;
  }
  return value;
};

export const emptyStringToZero = (
  value: string | number,
  originalValue: string | number
) => {
  if (typeof originalValue === 'string' && originalValue === '') {
    return '';
  }
  // @ts-ignore
  if (isNaN(value)) {
    return '';
  }
  return value;
};

export const nullToEmptyString = (
  value: string | number,
  originalValue: string | number
) => {
  if (originalValue === null) {
    return '';
  }
  return value;
};

export const roundTo2Decimal = (num: number) => {
  if (num) {
    return Math.round(num * 100) / 100;
  }
  return num;
};

export const titleCase = (input: string, limit?: number) => {
  let _input = input;
  if (limit) {
    _input = truncate(input, { length: limit });
  }
  return toLower(_input).replace(/\w+/g, capitalize);
};

export const snakeToTitle = (input: string) => {
  if (!input) {
    return '';
  }
  return titleCase(input.split('_').join(' '));
};

export const getDirtyFields = (
  oldValues: Record<string, any>,
  newValues: Record<string, any>
) => {
  const ret: Record<string, any> = {};
  Object.entries(newValues).forEach(([key, value]) => {
    if (key in oldValues) {
      if (oldValues[key] !== value) {
        ret[key] = value;
      }
    } else {
      ret[key] = value;
    }
  });
  return ret;
};

export const estimateLineitemTypeToCacheNameMap: Record<string, any> = {
  'work-doing': 'work-doing-lineitems',
  'work-not-doing': 'work-not-doing-lineitems',
  upgrades: 'upgrades-lineitems',
  discounts: 'discounts-lineitems',
  supplements: 'supplements-lineitems',
  changeorders: 'change-orders-lineitems',
};

export const heicToJpgMany = async (images: any[]) => {
  const newPhotos: any[] = [];
  for (const p of images) {
    const extn = getFileExtn(p?.name);
    if (['heic', 'heif'].includes(extn)) {
      const conversionResult: any = await heic2any({ blob: p });
      const updatedFile = new File([conversionResult], `${p?.name}.png`);
      newPhotos.push({
        file: updatedFile,
        id: v4(),
        selected: true,
      });
    } else {
      newPhotos.push({ file: p, id: v4(), selected: true });
    }
  }

  return newPhotos;
};

export const serverFileUrlToBlob = async (url: string) => {
  try {
    const { data } = await axios.get(url, {
      headers: {
        'Content-Type': 'image/*',
      },
      responseType: 'blob',
    });
    return data;
  } catch (error) {
    toast.error(readAxiosErr(error));
    return null;
  }
};

export const serverUrlToBlob = async (url: string) => {
  const sid = getItem(IMPERSONATE_SESSION_KEY) || getItem(SESSION_KEY);
  const response: any = await fetch(url, {
    method: 'GET',
    headers: { Authorization: `Bearer ${sid}` },
  });
  const blob = await response.blob();
  const objectUrl = URL.createObjectURL(blob);
  return objectUrl;
};

export const getBlob = (dataUri: string) => {
  if (!dataUri) return;
  const match = /^data:(.+);(.+),(.+)$/.exec(dataUri as string);

  if (!match) return;

  const mimeType = match[1];
  const data = match[3];
  const name = `${new Date().getTime().toString()}.png`; // replace with your desired file name
  const blob = new Blob([atob(data)], { type: mimeType });

  return {
    blob,
    name,
  };
};

export const generateOtp = (digits = 6) => {
  return Math.floor(Math.random() * 10 ** digits);
};

export const getUserGeoInfo = async () => {
  try {
    const { data } = await axios.get(
      'https://pro.ip-api.com/json/?key=GN7S483kqHyMpWB'
    );
    return data;
  } catch (err) {
    try {
      const { data } = await axios.get('https://api.ipdata.co/?api-key=test');
      const geoInfo = {
        as: '',
        city: data.city,
        country: data.country_name,
        countryCode: data.country_code,
        isp: '',
        lat: data.latitude,
        lon: data.longitude,
        org: '',
        query: data.ip,
        region: data.region_code,
        regionName: data.region,
        status: 'success',
        timezone: get(data, 'time_zone.name'),
        zip: data.postal,
      };
      return geoInfo;
    } catch (err2) {
      return {};
    }
  }
};

export const scrollToId = (id: string) => {
  document.getElementById(`${id}`)?.scrollIntoView({
    behavior: 'smooth',
    block: 'center',
  });
};

export const downloadBinaryAsPdf = (binary: any, filename = 'download.pdf') => {
  const blob = new Blob([binary], { type: 'application/pdf' });
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.setAttribute('hidden', '');
  a.setAttribute('href', url);
  a.setAttribute('download', filename);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
