import React from 'react';
import { SubmitHandler } from 'react-hook-form';
import clx from 'classnames';
import Tooltip from 'components/Tooltip';
import Icon from 'components/Icon';

type AppProps = {
  label: string | React.ReactNode;
  name: string;
  className?: string;
  labelClass?: string;
  onChange: SubmitHandler<any>;
  error?: string;
  loading?: boolean;
  size?: string;
  isChecked?: boolean;
  isPreserveState?: boolean;
  disabled?: boolean;
  info?: string;
};

const Checkbox = React.forwardRef<HTMLInputElement, AppProps>(
  (
    {
      name,
      label,
      onChange,
      className = '',
      error = null,
      loading = false,
      size = 'h-6 w-6',
      isChecked = false,
      isPreserveState = false,
      info = '',
      disabled = false,
      labelClass = '',
    },
    ref
  ) => {
    return (
      <div className={`space-y-1 mb-2 ${className}`}>
        <label
          className={clx('flex items-center space-x-1.5', {
            'opacity-60': disabled,
          })}
        >
          <input
            type="checkbox"
            className={clx(
              'outline-none focus:outline-none focus:ring-0 rounded-md',
              size
            )}
            name={name}
            onChange={onChange}
            ref={ref}
            disabled={loading || disabled}
            {...(isPreserveState && { checked: isChecked })}
          />
          <div
            className={clx(
              'text-text-medium text-sm relative bottom-[1px]',
              labelClass
            )}
          >
            {label}
          </div>
          {!!info && (
            <div>
              <Tooltip tooltipContent={info} width="240px">
                <Icon
                  name="info"
                  size={14}
                  className="text-text-lighter relative top-1"
                />
              </Tooltip>
            </div>
          )}
        </label>
        {error && <div className="text-error text-xs">*{error}</div>}
      </div>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
