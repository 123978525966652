import React, { useEffect, useRef } from 'react';
import { useController } from 'react-hook-form';
import clx from 'classnames';
import { ChromePicker } from 'react-color';
import Tooltip from 'components/Tooltip';
import useModal from 'hooks/useModal';
import BottomSheet from 'components/BottomSheet';
import useMedia from 'hooks/useMedia';
import Icon from 'components/Icon';
import Button, { Size, Variants } from 'components/Button';

type AppProps = {
  label: string;
  name: string;
  placeholder?: string;
  className?: string;
  control: any;
  required?: boolean;
  error?: string;
  showLabel?: boolean;
  trigger?: React.ReactNode;
  info?: string;
};

const ColorPicker: React.FC<AppProps> = ({
  name,
  label,
  control,
  className = '',
  showLabel = false,
  error = null,
  required = false,
  trigger = null,
  info = '',
}) => {
  const { field } = useController({ name, control });
  const [color, showColor, closeColor] = useModal();
  const defaultValue = useRef();

  useEffect(() => {
    defaultValue.current = field.value;
  }, []);

  const { isMobile } = useMedia();

  const handleClose = () => {
    field.onChange(defaultValue.current);
    closeColor();
  };

  const inputStyle = clx(
    'w-full outline-none placeholder:text-gray-300 text-text focus:outline-none focus:ring-0 border rounded-lg px-2 py-2.5 text-sm v-center space-x-1.5 cursor-pointer',
    {
      'focus:border-error-dark border-error': !!error,
      'focus:border-primary border-border ': !error,
    }
  );

  return (
    <>
      <div className={`space-y-1 mb-2 ${className}`}>
        {showLabel && (
          <div className="text-text-medium text-sm v-center">
            {label}
            {required && <span className="text-error">*</span>}
            {info && (
              <Tooltip tooltipContent={info}>
                <Icon name="info" className="text-text-light ml-2" size={16} />
              </Tooltip>
            )}
          </div>
        )}

        <div onClick={showColor}>
          {trigger || (
            <div className={inputStyle}>
              {field.value && (
                <div
                  className="h-4 w-4 rounded-md"
                  data-tooltip-id="color-tooltip"
                  style={{ backgroundColor: field.value }}
                />
              )}
              <span>{field.value || 'Select Color'}</span>
            </div>
          )}
        </div>
        {error && <div className="text-error text-xs">*{error}</div>}
      </div>
      <BottomSheet open={color} closeSheet={closeColor}>
        <div>
          <div className="apart">
            <div className="text-text-medium font-bold">{label}</div>
            <div>
              <Icon
                name="close"
                size={26}
                className="text-text-medium"
                onClick={handleClose}
              />
            </div>
          </div>
          <div className="mt-4">
            <ChromePicker
              className={clx('colorPicker bg-white shadow-none', {
                'w-screen': isMobile,
                '!w-[400px]': !isMobile,
              })}
              onChange={(e) => {
                field.onChange(e.hex);
              }}
              color={field.value}
              disableAlpha
            />
          </div>
          <div className="apart mt-4 space-x-8">
            <Button
              variant={Variants.Outline}
              size={Size.Medium}
              label="Close"
              onClick={handleClose}
            />
            <Button label="Select" size={Size.Medium} onClick={closeColor} />
          </div>
        </div>
      </BottomSheet>
    </>
  );
};

ColorPicker.displayName = 'ColorPicker';

export default ColorPicker;
