import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FieldType } from 'utils/enum';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Layout } from 'components/Form';
import { processFields, processInitialValues, processSchema } from 'utils/form';
import { createEmail, useEmailFieldConfig } from 'queries/emails';
import { useParams, useSearchParams } from 'react-router-dom';
import Button from 'components/Button';
import { useEmailRecipientsChoices } from 'queries/job';
import toast from 'react-hot-toast';
import { readAxiosErr } from 'utils/misc';
import SkeletonLoader, { ContentType } from 'components/SkeletonLoader';
import { createDocument } from 'queries/documents';
import usePageLoader from 'hooks/usePageLoader';
import Icon from 'components/Icon';
import { useEmailTemplatesChoices } from 'queries/company';

interface IFormValues {
  template?: number;
  recipients: number[];
  subject: string;
  message: string;
  attachments: any[];
  photos: number[];
  job_docs: number[];
}

type AppProps = {
  onSuccess: () => any;
  initial_recipient?: string;
};

const Form: React.FC<AppProps> = ({ onSuccess, initial_recipient }) => {
  const queryClient = useQueryClient();
  const { jobId = '' } = useParams();
  const [searchParams] = useSearchParams();
  const initialRecipient =
    initial_recipient || searchParams.get('initial_recipient') || '';
  const initialJobDoc = searchParams.get('initial_job_doc') || null;

  const [template, setTemplate] = useState<number>();
  const [showTemplate, setShowTemplate] = useState(false);
  const { data: config, isLoading } = useEmailFieldConfig({
    template,
    job: jobId,
  });
  const { startLoader, stopLoader } = usePageLoader();
  const [showAttachments, setShowAttachments] = useState(!!initialJobDoc);

  const addEmail = useMutation(
    (formData: any) => createEmail(formData, { job: jobId }),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async () => {
        await Promise.all([
          queryClient.invalidateQueries(['emails']),
          queryClient.invalidateQueries(['desktop-emails']),
          queryClient.removeQueries(['job-communications', jobId]),
        ]);

        queryClient.setQueryData(['job-stats', jobId], (old: any = {}) => ({
          ...old,
          emails: old?.emails + 1,
        }));
        return onSuccess();
      },
    }
  );

  const uploadDoc = useMutation((formData: any) => createDocument(formData));

  const schema = yup.object(
    processSchema(
      {
        template: yup.number(),
        recipients: yup.array().of(yup.number()),
        subject: yup.string(),
        message: yup.string(),
        attachments: yup.array().of(yup.mixed()).nullable(),
        photos: yup.array().of(yup.number()),
      },
      config,
      ['template']
    )
  );

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    reset,
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      recipients: initialRecipient ? [parseInt(initialRecipient)] : [],
      job_docs: initialJobDoc ? [parseInt(initialJobDoc)] : [],
    },
  });
  const _template = watch('template');

  useEffect(() => {
    if (config) {
      const defaultValues = processInitialValues(config);
      reset({
        ...defaultValues,
        recipients: defaultValues.recipients?.length
          ? defaultValues.recipients
          : initialRecipient
          ? [parseInt(initialRecipient)]
          : [],
      });
    }
  }, [config]);

  useEffect(() => {
    if (_template) {
      setTemplate(_template);
    }
  }, [_template]);

  const uploadAttachments = async (attachments: any[]) => {
    const documentIds: number[] = [];
    for (let i = 0; i < attachments.length; i += 1) {
      const doc = attachments[i];
      const payload = new FormData();
      payload.append('name', doc.name);
      payload.append('doc', doc);
      payload.append('job', jobId);
      const data = await uploadDoc.mutateAsync(payload);
      documentIds.push(data.id);
    }
    return documentIds;
  };

  const onSubmit: SubmitHandler<IFormValues> = async (data) => {
    const { attachments = [], ...payload } = data;
    let documentIds: number[] = [];
    if (attachments.length) {
      startLoader();
      documentIds = await uploadAttachments(attachments);
      stopLoader();
    }
    addEmail.mutate({ ...payload, attachments: documentIds, job: jobId });
  };

  const templateField = [
    {
      name: 'template',
      type: FieldType.AsyncSelect,
      label: 'Select Note Template',
      placeholder: 'Select Template',
      control,
      optionsPromise: useEmailTemplatesChoices,
    },
  ];

  const fields = [
    {
      name: 'recipients',
      type: FieldType.AsyncMultiSelect,
      label: 'Recipients',
      // @ts-ignore
      error: errors.recipients?.message,
      control,
      className: 'pt-1',
      // eslint-disable-next-line react-hooks/rules-of-hooks
      optionsPromise: () => useEmailRecipientsChoices(jobId),
      labelAction: showTemplate ? null : (
        <div
          className="text-xxs font-bold text-primary cursor-pointer"
          onClick={() => setShowTemplate(true)}
        >
          Create From Template
        </div>
      ),
    },
    {
      type: FieldType.Input,
      label: 'Subject',
      placeholder: 'Email Subject',
      error: errors.subject?.message,
      ...register('subject'),
    },
    {
      type: FieldType.TextArea,
      label: 'Message',
      placeholder: 'Type here...',
      error: errors.message?.message,
      row: 8,
      ...register('message'),
    },
  ];

  const fields2 = [
    {
      type: FieldType.JobPhotos,
      label: 'Job Photos',
      name: 'photos',
      // @ts-ignore
      error: errors.photos?.message,
      reset: () => setValue('photos', []),
      job: jobId,
      control,
    },
    {
      type: FieldType.JobDocs,
      label: 'Job Documents',
      name: 'job_docs',
      // @ts-ignore
      error: errors.job_docs?.message,
      reset: () => setValue('job_docs', []),
      job: jobId,
      control,
    },
    {
      type: FieldType.MultiFile,
      label: 'Attachments',
      name: 'attachments',
      // @ts-ignore
      error: errors.attachments?.message,
      reset: () => setValue('attachments', []),
      // className: isMobile ? '' : 'w-[32.5%] relative bottom-2',
      boxClass: 'h-28',
      control,
    },
  ];

  if (isLoading) {
    return <SkeletonLoader contentType={ContentType.Form} size={5} />;
  }

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        {showTemplate && (
          <Layout
            fields={processFields(templateField, config, ['template'])}
            loading={addEmail.isLoading}
            className="mb-4"
          />
        )}
        <Layout
          fields={processFields(fields, config)}
          loading={uploadDoc.isLoading || addEmail.isLoading}
        />

        {showAttachments ? (
          <div className="mt-3">
            <Layout
              fields={processFields(fields2, config)}
              loading={uploadDoc.isLoading || addEmail.isLoading}
            />
          </div>
        ) : (
          <div
            className="text-primary mt-2 text-sm v-center space-x-1 cursor-pointer"
            onClick={() => setShowAttachments((t) => !t)}
          >
            <Icon name="attachments" size={16} />
            <span>Add Attachments</span>
          </div>
        )}
        <Button
          label={
            uploadDoc.isLoading || addEmail.isLoading
              ? 'Sending... (Please wait)'
              : 'Send Email'
          }
          className="mt-8"
          loading={uploadDoc.isLoading || addEmail.isLoading}
        />
      </form>
    </div>
  );
};

export default Form;
