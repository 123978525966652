import BottomSheet from 'components/BottomSheet';
import Button, { Size as BtnSize } from 'components/Button';
import Icon, { Loader } from 'components/Icon';
import Logo, { Size } from 'components/Logo';
import useAuth from 'hooks/useAuth';
import useModal from 'hooks/useModal';
import { updateSalesRabbitWebhook, useIntegrationMeta } from 'queries/company';
import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FieldType } from 'utils/enum';
import { Layout } from 'components/Form';
import { readAxiosErr, urlRegex } from 'utils/misc';
import { useMutation, useQueryClient } from '@tanstack/react-query';

const payloadData = [
  { label: 'id', required: false, type: 'string or number' },
  { label: 'first_name', required: true, type: 'string' },
  { label: 'last_name', required: false, type: 'string' },
  { label: 'street_address', required: true, type: 'string' },
  { label: 'city', required: false, type: 'string' },
  { label: 'state', required: true, type: 'string' },
  { label: 'zip_code', required: true, type: 'string' },
  { label: 'created_from_latitude', required: false, type: 'null' },
  { label: 'created_from_longitude', required: false, type: 'null' },
  { label: 'email', required: false, type: 'string' },
  { label: 'phone_number', required: false, type: 'string' },
  { label: 'lead_source', required: false, type: 'string' },
  { label: 'lead_status', required: false, type: 'string' },
  { label: 'company_id', required: false, type: 'string' },
  { label: 'company_abbr', required: false, type: 'string' },
  { label: 'note', required: false, type: 'string' },
  { label: 'notes', required: false, type: 'string' },
  { label: 'job_type', required: false, type: 'string' },
  { label: 'bid_type', required: false, type: 'string' },
  { label: 'rep', required: false, type: 'string' },
  { label: 'rep_2', required: false, type: 'string' },
  {
    label: 'sales_rabbit_company_id',
    required: false,
    type: 'string or number',
  },
  { label: 'sales_rabbit_lead_id', required: false, type: 'string or number' },
  { label: 'sales_rabbit_rep_id', required: false, type: 'string or number' },
  { label: 'sales_rabbit_rep_2_id', required: false, type: 'string or number' },
];

type AppProps = {
  initial?: string;
  open: boolean;
  onClose: () => any;
};

interface IFormValues {
  sales_rabbit_webhook_url: string;
}

const SalesRabbitWebhook: React.FC<AppProps> = ({
  initial = '',
  open,
  onClose,
}) => {
  const queryClient = useQueryClient();

  const updateMutation = useMutation(
    (formData: any) => updateSalesRabbitWebhook(formData),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['integration-meta', 'company']);
        onClose();
      },
    }
  );

  const schema = yup.object({
    sales_rabbit_webhook_url: yup
      .string()
      .matches(urlRegex, {
        message: 'Please enter valid url',
        excludeEmptyString: true,
      })
      .optional(),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      sales_rabbit_webhook_url: initial,
    },
  });

  const onSubmit: SubmitHandler<IFormValues> = async (formData) => {
    updateMutation.mutate(formData);
  };

  const fields = [
    {
      type: FieldType.Input,
      label: 'SalesRabbit Webhook Url',
      placeholder: 'Webhook Url',
      error: errors.sales_rabbit_webhook_url?.message,
      ...register('sales_rabbit_webhook_url'),
    },
  ];

  return (
    <BottomSheet open={open}>
      <div>
        <div className="apart pb-4">
          <div className="page-heading">SalesRabbit Webhook Url</div>
          <div>
            <Icon name="close" onClick={onClose} />
          </div>
        </div>
        <div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Layout fields={fields} />
            <Button
              label="Update Webhook"
              loading={updateMutation.isLoading}
              className="mt-4"
            />
          </form>
        </div>
      </div>
    </BottomSheet>
  );
};

const SalesRabbit = () => {
  const { userInfo } = useAuth();
  const { data: meta, isLoading } = useIntegrationMeta();
  const [payload, showPayload, closePayload] = useModal();
  const [webhook, showWebhook, closeWebhook] = useModal();
  const queryClient = useQueryClient();

  const deleteMutation = useMutation(
    () => updateSalesRabbitWebhook({ sales_rabbit_webhook_url: '' }),
    {
      onError: (err: any) => {
        toast.error(readAxiosErr(err));
      },
      onSuccess: async () => {
        await queryClient.invalidateQueries(['integration-meta', 'company']);
      },
    }
  );

  const renderSRtoRL = () => {
    const rooflinkWebhook = `https://api.roof.link/api/guest/add_lead/?company_id=${userInfo?.primary_company.id}&company_abbr=${userInfo?.primary_company.abbr}`;

    return (
      <>
        <div className="card border !p-4">
          <div className="v-center space-x-2">
            <div className="v-center space-x-3 ">
              <img
                src={require('images/integrations/salesrabbit_full.png')}
                className="h-10 object-contain"
              />

              <Icon
                name="caret-right-outline"
                size={40}
                className="text-text-light"
              />
              <Logo size={Size.ExtraSmall} />
            </div>
          </div>
          <CopyToClipboard
            text={rooflinkWebhook}
            onCopy={() => toast.success('Link copied to clipboard')}
          >
            <div className="mt-2">
              <div className="text-sm text-text-lighter">
                Use this webhook to sync leads from SalesRabbit to Rooflink
              </div>
              <div className="v-center space-x-2 text-primary text-sm">
                <div>{rooflinkWebhook}</div>
                <Icon
                  name="copy"
                  size={16}
                  className="cursor-pointer text-text-light"
                />
              </div>
            </div>
          </CopyToClipboard>
          <div className="mt-4 mb-2">
            <div className="text-sm text-text-light">
              Details for this integration
            </div>
            <div className="pl-4">
              <CopyToClipboard
                text={userInfo?.primary_company.id || ''}
                onCopy={() => toast.success('Id copied to clipboard')}
              >
                <div className="v-center space-x-2">
                  <div className="text-sm text-text-lighter">Company Id:</div>
                  <div className="v-center space-x-2 text-primary text-sm">
                    <div>{userInfo?.primary_company.id}</div>
                    <Icon
                      name="copy"
                      size={12}
                      className="cursor-pointer text-text-light"
                    />
                  </div>
                </div>
              </CopyToClipboard>
              <CopyToClipboard
                text={userInfo?.primary_company.abbr || ''}
                onCopy={() => toast.success('Abbr copied to clipboard')}
              >
                <div className="v-center space-x-2">
                  <div className="text-sm text-text-lighter">Company Abbr:</div>
                  <div className="v-center space-x-2 text-primary text-sm">
                    <div>{userInfo?.primary_company.abbr}</div>
                    <Icon
                      name="copy"
                      size={12}
                      className="cursor-pointer text-text-light"
                    />
                  </div>
                </div>
              </CopyToClipboard>
            </div>
          </div>
          <div
            className="v-center space-x-1 mt-4 cursor-pointer bg-gray-100 px-1 max-w-fit"
            onClick={showPayload}
          >
            <Icon name="webhook" size={16} />
            <div className="text-sm text-text-medium">
              Click to view details on webhook payload
            </div>
          </div>
        </div>
        {payload && (
          <BottomSheet
            open={payload}
            closeSheet={closePayload}
            maxWidth="max-w-2xl"
          >
            <div>
              <div className="apart">
                <div className="page-heading">Sync Lead Payload Details</div>
                <Icon name="close" onClick={closePayload} />
              </div>
              <div>
                <div>
                  <CopyToClipboard
                    text={rooflinkWebhook}
                    onCopy={() => toast.success('Link copied to clipboard')}
                  >
                    <div className="mt-2">
                      <div className="text-sm text-text-lighter">
                        Use this webhook to sync leads from SalesRabbit to
                        Rooflink
                      </div>
                      <div className="v-center space-x-2 text-primary text-sm">
                        <div>
                          <span>POST</span>&nbsp;&nbsp;
                          {rooflinkWebhook}
                        </div>
                        <Icon
                          name="copy"
                          size={16}
                          className="cursor-pointer text-text-light"
                        />
                      </div>
                    </div>
                  </CopyToClipboard>
                </div>
                <div className="text-sm text-text-medium mt-4 mb-2">
                  Content-Type: application/json
                </div>
                <div className="pl-2 border-l-2 bg-gray-100">
                  {payloadData?.map((d) => (
                    <div key={d.label} className="v-center space-x-2 space-y-1">
                      <div className="font-bold text-sm">
                        {d.label}{' '}
                        {d.required && <span className="text-red-500">*</span>}
                      </div>
                      <div className="text-text-lighter text-sm">{d.type}</div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </BottomSheet>
        )}
      </>
    );
  };

  const renderRLtoSR = () => (
    <>
      <div className="card border !p-4">
        <div className="v-center space-x-2">
          <div className="v-center space-x-3 ">
            <Logo size={Size.ExtraSmall} />

            <Icon
              name="caret-right-outline"
              size={40}
              className="text-text-light"
            />

            <img
              src={require('images/integrations/salesrabbit_full.png')}
              className="h-10 object-contain"
            />
          </div>
        </div>
        <div className="mt-2">
          {(() => {
            if (isLoading) {
              return <Loader />;
            }
            if (meta?.salesrabbit) {
              return (
                <div>
                  <div className="text-sm text-text-lighter">
                    You have added below SalesRabbit webhook url
                  </div>
                  <div className="v-center space-x-8">
                    <div className=" text-text-medium">{meta?.salesrabbit}</div>
                    <div className="v-center space-x-2">
                      <Icon
                        name="edit"
                        size={18}
                        className="text-primary"
                        onClick={showWebhook}
                      />
                      <Icon
                        name="delete"
                        size={18}
                        className="text-error"
                        onClick={() => deleteMutation.mutate()}
                      />
                    </div>
                  </div>
                </div>
              );
            }
            return (
              <div>
                <div className="max-w-min mt-2">
                  <Button
                    label="Add webhook url"
                    leftIcon="plus"
                    size={BtnSize.Medium}
                    onClick={showWebhook}
                  />
                </div>
                <div className="mt-2 text-xs text-text-light">
                  *Contact your SalesRabbit Account Rep to get your SalesRabbit
                  webhook url.
                </div>
              </div>
            );
          })()}
        </div>
      </div>
      {webhook && (
        <SalesRabbitWebhook
          open={webhook}
          onClose={closeWebhook}
          initial={meta?.salesrabbit}
        />
      )}
    </>
  );

  return (
    <div className="bg-white">
      <div>
        <div className="py-2 px-4 apart bg-primary-negative">
          <div className="text-sm font-bold v-center space-x-1">
            <div>SalesRabbit Integration</div>
          </div>
        </div>
      </div>
      <div className="p-4">
        <div className="text-xs text-text-lighter mb-4">
          Select how you want to move data between Rooflink and SalesRabbit
        </div>
        <div className="space-y-4">
          {renderSRtoRL()}
          {renderRLtoSR()}
        </div>
      </div>
    </div>
  );
};

export default SalesRabbit;
