import { useQuery } from '@tanstack/react-query';
import apiService from 'utils/apiService';

// ******** Get Todos For Job *********
export const getMyTodos = async (user?: number) => {
  if (!user) {
    return [];
  }
  const { data } = await apiService.get(
    `/api/light/todos/?date_completed__isnull=True&employee=${user}`
  );
  return data;
};

export const useMyTodos = (user?: number, enabled = true) => {
  return useQuery(['my-todos'], () => getMyTodos(user), {
    staleTime: 15 * 60 * 1000,
    enabled,
  });
};

// ******** Get Todos For Job *********
export const getJobTodos = async (jobId: string) => {
  const { data } = await apiService.get(`/api/light/jobs/${jobId}/todos/`);
  return data;
};

export const useJobTodos = (jobId: string) => {
  return useQuery(['job-todos', jobId], () => getJobTodos(jobId), {
    staleTime: 15 * 60 * 1000,
  });
};

// ********* Get Todo Detail *********
const getTodoDetail = async (id: string, job?: string) => {
  const { data } = await apiService.get(`/api/light/todos/${id}/`, { job });
  return data;
};

export const useTodoDetail = (id: string, job: string) => {
  return useQuery(['todo-detail', id], () => getTodoDetail(id, job), {
    staleTime: 0,
  });
};

// ********** Get Todo field config **********
const getTodoFieldConfig = async (q: Record<string, any>) => {
  const { data } = await apiService.get(`/api/light/todos/fields_config/`, q);
  return data;
};

interface IFieldConfig {
  job?: string;
  todo?: string;
}

export const useTodoFieldConfig = (q: IFieldConfig = {}) => {
  const { job, todo } = q;
  return useQuery(
    ['todo-field-config', { job, todo }],
    () => getTodoFieldConfig({ job, todo }),
    {
      staleTime: todo ? 0 : Infinity,
    }
  );
};

// ****** Create todo *******
export const createTodo = async (payload: Record<string, any>) => {
  const { data } = await apiService.post('/api/light/todos/', payload);
  return data;
};

export const updateTodo = async (id: string, payload: Record<string, any>) => {
  const { data } = await apiService.put(`/api/light/todos/${id}/`, payload);
  return data;
};

export const deleteTodo = async (id: string) => {
  await apiService.delete(`/api/light/todos/${id}/`);
};

export const completeTodo = async (id: string) => {
  const { data } = await apiService.post(`/api/light/todos/${id}/complete/`, {
    completed: true,
  });
  return data;
};
