import React from 'react';
import clx from 'classnames';
import {
  MdLogin,
  MdOutlineLocationOn,
  MdOutlineRoofing,
  MdCalendarToday,
  MdOutlineDashboard,
  MdOutlineMessage,
  MdOutlinePeople,
  MdSearch,
  MdFilterList,
  MdFilterAlt,
  MdOutlineDirections,
  MdOutlineSms,
  MdOutlineEmail,
  MdOutlineReply,
  MdOutlineContentCopy,
  MdFacebook,
  MdOutlineNotifications,
  MdOutlineConstruction,
  MdOutlineBugReport,
  MdCode,
  MdClose,
  MdOutlineEventAvailable,
  MdOutlineMyLocation,
  MdKeyboardArrowUp,
  MdKeyboardArrowDown,
  MdKeyboardArrowRight,
  MdKeyboardArrowLeft,
  MdPhotoLibrary,
  MdMoreVert,
  MdOutlineFileUpload,
  MdAttachFile,
  MdCloudQueue,
  MdPendingActions,
  MdOutlineWarningAmber,
  MdAlarm,
  MdOutlineDelete,
  MdPayment,
  MdLogout,
  MdLock,
  MdOutlineVerifiedUser,
  MdOutlinePersonAddAlt,
  MdSpeakerNotes,
  MdOutlineAttachMoney,
  MdInfoOutline,
  MdOutlineDocumentScanner,
  MdOutlineVerified,
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
  MdCancel,
  MdPageview,
  MdTimeline,
  MdDragIndicator,
  MdTitle,
  MdSave,
  MdCrop,
  MdExpand,
  MdShortText,
  MdOutlineWidgets,
  MdLeaderboard,
  MdPrint,
  MdOutlineUnarchive,
  MdOutlineLocalShipping,
  MdOutlineHelpOutline,
  MdOutlineManageAccounts,
  MdWebhook,
} from 'react-icons/md';
import {
  BiMinus,
  BiPlus,
  BiSortUp,
  BiSortDown,
  BiCaretDownCircle,
  BiCaretUpCircle,
  BiMenu,
  BiSupport,
  BiGridVertical,
  BiEditAlt,
  BiDetail,
  BiMoney,
  BiLinkExternal,
  BiCollapse,
  BiRefresh,
  BiColorFill,
  BiArrowToRight,
  BiExit,
  BiCodeCurly,
} from 'react-icons/bi';
import { GrStatusUnknown, GrUserWorker } from 'react-icons/gr';
import {
  FaLinkedin,
  FaCaretDown,
  FaCaretUp,
  FaCalculator,
  FaTools,
  FaTape,
  FaClipboardList,
  FaPercent,
  FaSpinner,
  FaCheckCircle,
  FaCloudShowersHeavy,
  FaDrawPolygon,
  FaWarehouse,
  FaHouseDamage,
  FaExclamation,
  FaUser,
  FaUserNinja,
  FaUserTie,
  FaSignature,
  FaFileContract,
  FaBan,
  FaSyncAlt,
  FaRegAddressCard,
  FaSourcetree,
  FaTasks,
  FaFolder,
  FaUserCheck,
  FaUserTimes,
  FaMoneyCheckAlt,
  FaArchive,
  FaVideo,
  FaCheckDouble,
} from 'react-icons/fa';
import {
  AiFillStar,
  AiOutlineStar,
  AiOutlineNumber,
  AiOutlineAppstore,
  AiOutlineBarChart,
  AiOutlineLineChart,
  AiFillHeart,
  AiOutlineClockCircle,
  AiOutlineTags,
  AiOutlineLogin,
  AiOutlineCloudDownload,
  AiOutlineSchedule,
  AiOutlinePlusCircle,
  AiOutlineDashboard,
  AiOutlineSelect,
  AiOutlineShoppingCart,
  AiOutlineMobile,
  AiOutlineReload,
  AiOutlineTeam,
  AiOutlineFunnelPlot,
  AiOutlineHistory,
} from 'react-icons/ai';
import {
  BsMap,
  BsArrowRight,
  BsArrowLeft,
  BsList,
  BsGrid,
  BsCamera,
  BsDownload,
  BsCardImage,
  BsCheckLg,
  BsPaperclip,
  BsFillQuestionCircleFill,
  BsShop,
  BsGlobe,
  BsGoogle,
  BsFacebook,
  BsToggleOn,
  BsPen,
  BsTextParagraph,
  BsFillSkipForwardFill,
} from 'react-icons/bs';
import {
  FiDatabase,
  FiPhone,
  FiPlus,
  FiUser,
  FiBriefcase,
  FiCheckCircle,
  FiAlertTriangle,
  FiSend,
  FiPlay,
  FiTrendingUp,
  FiLayers,
} from 'react-icons/fi';
import {
  RiOrganizationChart,
  RiLinkUnlink,
  RiCloseCircleFill,
  RiSurveyLine,
  RiTableLine,
  RiBankLine,
  RiErrorWarningLine,
} from 'react-icons/ri';
import {
  VscChecklist,
  VscFilePdf,
  VscSettings,
  VscJson,
} from 'react-icons/vsc';
import {
  IoShapesOutline,
  IoDocumentTextOutline,
  IoSettingsOutline,
  IoFlag,
} from 'react-icons/io5';
import {
  CgProfile,
  CgDetailsLess,
  CgOrganisation,
  CgUndo,
  CgPushChevronLeft,
  CgPushChevronRight,
  CgTemplate,
  CgSelectR,
  CgPlayListAdd,
  CgArrowsExchange,
} from 'react-icons/cg';
import { FcInspection, FcCancel, FcGoogle } from 'react-icons/fc';
import {
  SiQuickbooks,
  SiStripe,
  SiZapier,
  SiMicrosoftexcel,
  SiMicrosoftword,
  SiDatabricks,
  SiYelp,
} from 'react-icons/si';
import { TiFlowChildren } from 'react-icons/ti';
import { GiClick } from 'react-icons/gi';
import { GrCatalogOption } from 'react-icons/gr';
import { HiSwitchVertical, HiSwitchHorizontal } from 'react-icons/hi';
import { IoMdCheckboxOutline } from 'react-icons/io';

// IMPORTANT: Keep this dictionary alphabetical sorted
export const iconMap: Record<string, any> = {
  account: MdOutlineManageAccounts,
  'add-user': MdOutlinePersonAddAlt,
  'add-text': CgPlayListAdd,
  address: FaRegAddressCard,
  advance: VscSettings,
  alarm: MdAlarm,
  alert: FiAlertTriangle,
  apps: AiOutlineAppstore,
  archive: FaArchive,
  'arrow-left': BsArrowLeft,
  'arrow-right': BsArrowRight,
  attachments: MdAttachFile,
  back: MdOutlineArrowBackIos,
  bank: RiBankLine,
  bar: AiOutlineBarChart,
  ban: FaBan,
  bell: MdOutlineNotifications,
  bug: MdOutlineBugReport,
  calendar: MdCalendarToday,
  camera: BsCamera,
  cancel: MdCancel,
  cart: AiOutlineShoppingCart,
  catalogue: GrCatalogOption,
  checkbox: IoMdCheckboxOutline,
  checklist: VscChecklist,
  click: GiClick,
  'cloud-download': AiOutlineCloudDownload,
  code: MdCode,
  collapse: BiCollapse,
  company: CgOrganisation,
  copy: MdOutlineContentCopy,
  crop: MdCrop,
  crew: MdOutlineConstruction,
  currentLocation: MdOutlineMyLocation,
  'caret-down-plain': FaCaretDown,
  'caret-up-plain': FaCaretUp,
  'caret-down': BiCaretDownCircle,
  'caret-up': BiCaretUpCircle,
  'caret-up-outline': MdKeyboardArrowUp,
  'caret-down-outline': MdKeyboardArrowDown,
  'caret-left-outline': MdKeyboardArrowLeft,
  'caret-right-outline': MdKeyboardArrowRight,
  check: FiCheckCircle,
  'check-circle': FaCheckCircle,
  calculator: FaCalculator,
  clock: AiOutlineClockCircle,
  close: MdClose,
  'close-circle': RiCloseCircleFill,
  'color-fill': BiColorFill,
  contract: FaFileContract,
  'dashboard-guage': AiOutlineDashboard,
  dashboard: MdOutlineDashboard,
  'data-source': BiCodeCurly,
  db: FiDatabase,
  declined: FcCancel,
  description: MdSpeakerNotes,
  delete: MdOutlineDelete,
  detail: CgDetailsLess,
  'detail-alt': BiDetail,
  direction: MdOutlineDirections,
  directory: RiOrganizationChart,
  document: IoDocumentTextOutline,
  dollar: MdOutlineAttachMoney,
  download: BsDownload,
  drag: MdDragIndicator,
  draw: FaDrawPolygon,
  edit: BiEditAlt,
  email: MdOutlineEmail,
  event: MdOutlineEventAvailable,
  excel: SiMicrosoftexcel,
  exchange: CgArrowsExchange,
  exclamation: FaExclamation,
  exit: BiExit,
  expand: MdExpand,
  'external-link': BiLinkExternal,
  facebook: MdFacebook,
  'facebook-alt': BsFacebook,
  flag: IoFlag,
  filter: MdFilterList,
  'filter-alt': MdFilterAlt,
  folder: FaFolder,
  funnel: AiOutlineFunnelPlot,
  globe: BsGlobe,
  google: BsGoogle,
  'google-alt': FcGoogle,
  grid: BsGrid,
  'grid-vertical': BiGridVertical,
  heart: AiFillHeart,
  help: MdOutlineHelpOutline,
  'house-damage': FaHouseDamage,
  image: BsCardImage,
  info: MdInfoOutline,
  'inspection-alt': FaClipboardList,
  inspection: FcInspection,
  layers: FiLayers,
  integration: RiLinkUnlink,
  leaderboard: MdLeaderboard,
  'lead-sources': FaSourcetree,
  line: AiOutlineLineChart,
  linkedin: FaLinkedin,
  list: BsList,
  location: MdOutlineLocationOn,
  lock: MdLock,
  login: MdLogin,
  'login-alt': AiOutlineLogin,
  logout: MdLogout,
  'long-text': BsTextParagraph,
  map: BsMap,
  'map-plus': FiPlus,
  material: SiDatabricks,
  measurement: FaTape,
  menu: BiMenu,
  minus: BiMinus,
  mobile: AiOutlineMobile,
  money: BiMoney,
  'money-alt': FaMoneyCheckAlt,
  more: MdMoreVert,
  next: MdOutlineArrowForwardIos,
  number: AiOutlineNumber,
  notes: MdOutlineMessage,
  'outline-plus': AiOutlinePlusCircle,
  'paper-clip': BsPaperclip,
  payments: MdPayment,
  percent: FaPercent,
  pen: BsPen,
  pdf: VscFilePdf,
  pending: MdPendingActions,
  phone: FiPhone,
  photos: MdPhotoLibrary,
  play: FiPlay,
  plus: BiPlus,
  print: MdPrint,
  profile: CgProfile,
  prospect: FiBriefcase,
  qb: SiQuickbooks,
  question: BsFillQuestionCircleFill,
  queue: MdCloudQueue,
  'quick-view': MdPageview,
  recent: AiOutlineHistory,
  read: FaCheckDouble,
  refresh: BiRefresh,
  reload: AiOutlineReload,
  reply: MdOutlineReply,
  reorder: HiSwitchVertical,
  role: FaUserTie,
  roof: MdOutlineRoofing,
  save: MdSave,
  'save-report': MdOutlineDocumentScanner,
  schedule: AiOutlineSchedule,
  search: MdSearch,
  select: AiOutlineSelect,
  select_alt: CgSelectR,
  send: FiSend,
  setting: IoSettingsOutline,
  shapes: IoShapesOutline,
  'shift-left': CgPushChevronLeft,
  'shift-right': CgPushChevronRight,
  shipping: MdOutlineLocalShipping,
  shop: BsShop,
  'short-text': MdShortText,
  signature: FaSignature,
  skip: BsFillSkipForwardFill,
  'slide-right': BiArrowToRight,
  sms: MdOutlineSms,
  'sort-up': BiSortUp,
  'sort-down': BiSortDown,
  spinner: FaSpinner,
  star: AiFillStar,
  'star-outline': AiOutlineStar,
  stripe: SiStripe,
  support: BiSupport,
  survey: RiSurveyLine,
  switch: HiSwitchHorizontal,
  sync: FaSyncAlt,
  table: RiTableLine,
  tags: AiOutlineTags,
  tasks: TiFlowChildren,
  team: AiOutlineTeam,
  template: CgTemplate,
  tick: BsCheckLg,
  timeline: MdTimeline,
  title: MdTitle,
  todo: FaTasks,
  toggle: BsToggleOn,
  tools: FaTools,
  trend: FiTrendingUp,
  upload: MdOutlineFileUpload,
  unarchive: MdOutlineUnarchive,
  undo: CgUndo,
  unknown: GrStatusUnknown,
  user: FiUser,
  'user-active': FaUserCheck,
  'user-alt': FaUser,
  'user-inactive': FaUserTimes,
  'user-ninja': FaUserNinja,
  users: MdOutlinePeople,
  verified: MdOutlineVerifiedUser,
  verify: MdOutlineVerified,
  video: FaVideo,
  warehouse: FaWarehouse,
  warning: MdOutlineWarningAmber,
  'warning-alt': RiErrorWarningLine,
  weather: FaCloudShowersHeavy,
  webhook: VscJson,
  'webhook-alt': MdWebhook,
  widget: MdOutlineWidgets,
  word: SiMicrosoftword,
  workorder: GrUserWorker,
  yelp: SiYelp,
  zapier: SiZapier,
};

const imgMap: Record<string, string> = {
  sumoquote: require('./custom/sumuquote.png'),
};

type AppProps = {
  name: string;
  size?: number;
  onClick?: (...args: any[]) => void;
  className?: string;
  disabled?: boolean;
  style?: Record<string, any>;
};

const Icon: React.FC<AppProps> = ({
  name,
  size = 24,
  className,
  onClick,
  disabled = false,
  style = {},
}) => {
  const imgSrc = imgMap[name];
  if (imgSrc) {
    return <img src={imgSrc} className={className} />;
  }

  const IconComponent = iconMap[name];
  if (!IconComponent) {
    return null;
  }
  return (
    <IconComponent
      size={size}
      onClick={disabled ? () => null : onClick}
      className={clx(className, { 'cursor-pointer': !!onClick })}
      style={style}
    />
  );
};

export default Icon;
export { default as Loader } from './custom/loader';
export { default as Companycam } from './custom/Companycam';
export { default as Zapier } from './custom/Zapier';
export { default as Quickbooks } from './custom/QB';
export { default as Outlook } from './custom/Outlook';
export { default as Enterprise } from './custom/Enterprise';
