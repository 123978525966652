import React from 'react';
import clx from 'classnames';
import rlLogo from 'images/logo.png';
import rlIcon from 'images/logoOnly.png';

export enum Size {
  ExtraSmall = 'extrasmall',
  Small = 'small',
  Medium = 'medium',
  Large = 'large',
}

export enum Variant {
  Full = 'full',
  Icon = 'icon',
}

type AppProps = {
  size?: Size;
  variant?: Variant;
};

const Logo: React.FC<AppProps> = ({
  size = Size.Medium,
  variant = Variant.Full,
}) => {
  const imageStyle = clx('object-contain', {
    'h-6': size === Size.ExtraSmall,
    'h-8': size === Size.Small,
    'h-10': size === Size.Medium,
    'h-12': size === Size.Large,
  });

  if (variant === Variant.Icon) {
    return <img className={imageStyle} src={rlIcon} alt="RoofLink" />;
  }
  return <img className={imageStyle} src={rlLogo} alt="RoofLink" />;
};

export default Logo;
